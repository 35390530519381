// footer
.footer {
    background-color: $section-bg;
    padding-top: 4rem;
    text-align: center;

    .bg-dark {
        background-color: $primary !important;
    }

    .footer-sitelinks {
        .container-holder {
            margin: 0;
            .column.default {
                padding: 1.5rem;
                margin: 2.5rem 0;
                border-radius: 1rem;
            }
        }

        .footer-text {
            .address {
                strong {
                    font-size: 18px;
                }
            }

            a {
                text-decoration: none !important;
                &:hover {
                    text-decoration: underline !important;
                }
            }

            .btn-contact {
                background-color: $white;
                color: $primary !important;
                padding: 1rem;
                display: inline-block;
                font-size: 14px;
                font-weight: bold;
                width: 100%;
                max-width: 300px;
                border-radius: 3px;
                &:hover {
                    background-color: $secondary;
                    text-decoration: none !important;
                }
            }
        }

        // footer socila media
        ul.social-media {
            justify-content: center;
            align-items: center;

            li {
                line-height: normal;
                &:not(:first-child) {
                    margin-left: 1.5rem;
                }
                a {
                    line-height: normal;
                    font-size: 1.5rem;

                    &:hover {
                        color: $secondary !important;
                    }

                    .list-item-title {
                        display: none;
                    }
                }
            }
        }

        // footer-logolink
        .footer-logolink {
            .list {
                justify-content: center;
                align-items: center;
                margin-top: 10px;

                .list-item {
                    &:not(:first-child) {
                        margin-left: 1.5rem;
                    }
                }
            }
        }
    }

    .footer-logo {
        overflow: hidden;
        margin-bottom: 1.875rem;

        .footer-block-img {
            picture {
                display: inline-block;
                position: relative;

                @include media-breakpoint-down(xs) {
                    height: 160px;
                    width: 100%;
                    img {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 100%;
                    }
                }

                &::before,
                &::after {
                    content: '';
                    display: block;
                    height: 100%;
                    width: 300%;
                    background-image: url('/images/footer-block-after.svg');
                    background-repeat: repeat-x;
                    position: absolute;
                    top: 0;
                }
    
                &::before {
                    left: 100%;
                }
                
                &::after {
                    right: 100%;
                }
            }

        }
    }

    .footer-copyright {
        background-color: $white;
        padding: 10px 0;

        .footer-link {
            .list {
                justify-content: center;
                align-items: center;
                .list-item {
                    &:not(:first-child) {
                        margin-left: 1.5rem;
                    }
                }
            }
        }
    }
}
