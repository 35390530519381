.faq-overview {
    .faq-category {
        background-color: $box-bg;
        border-radius: 15px;
        padding: 1rem;

        .faq-title {
            h2 {
                font-size: 1rem;
            }

            .faq-icon {
                top: -4px;
            }
        }

        .faq-items {
            .faq-item {
                .faq-question {
                    background-color: $white;
                    border-color: $white;
                    border-radius: 4px;
                }
                .faq-answer {
                    margin-bottom: 5px;
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}