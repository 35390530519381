.gallery-section {
    @include media-breakpoint-up(md) {
        --column-width: 260px;
    }

    @include media-breakpoint-up(lg) {
        --column-width: 350px;
    }

    .image-collection {
        .gallery-horizontal {
            .gallery-item.large-item {
                position: relative;

                @include media-breakpoint-up(md) {
                    flex: 0 0 calc(100% - var(--column-width));
                    max-width: calc(100% - var(--column-width));
                }

                .gallery-link {
                    position: static;
                }
            }

            .gallery-thumbs {
                @include media-breakpoint-up(md) {
                    flex: 0 0 var(--column-width);
                    max-width: var(--column-width);
                }

                .gallery-item {
                    flex: 0 0 100%;
                    max-width: 100%;

                    @include media-breakpoint-down(sm) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }

            .gallery-item {
                margin-bottom: 5px;
                padding: 0 3px;
                .gallery-link {
                    border-radius: 4px;
                    height: 100%;
                    .gallery-img {
                        height: 100%;
                    }
                }

                &.last-item {
                    .gallery-caption {
                        font-size: 14px;
                        font-weight: bold;
                        background: $white;
                        border: 1px solid $primary;
                        border-radius: 3px;
                        color: $primary;
                        padding: 10px 18px;
                        top: unset;
                        left: unset;
                        right: 1rem;
                        bottom: 1rem;
                        line-height: normal;

                        i {
                            margin-left: 10px;
                            line-height: normal;
                        }


                        &::before {
                            display: none;
                        }
                    }
                }
            }

            .gallery-label {
                background: $primary;
                color: $white;
                border-radius: 5px;
                font-size: 1rem;
                line-height: normal;
                font-weight: bold;
                font-family: $font-family-secondary;
                padding: 10px 12px;
                right: 20px;
                top: -20px;
            }
        }
    }
}

.image-collection {
    .gallery {
        .gallery-item {
            margin-bottom: 5px;
            padding: 0 3px;
            .gallery-link {
                border-radius: 4px;
            }
        }
    }
}
