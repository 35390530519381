// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	padding: 1rem 1.875rem;
	font-size: 14px;
	line-height: normal;

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: lighten($primary, 20%);
			border-color: lighten($primary, 20%);
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		color: $primary;
		border-color: $primary;
		&:hover {
			background-color: lighten($primary, 20%);
			border-color: lighten($primary, 20%);
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-white {
		color: $white;
		border-color: $white;
		&:hover {
			background-color: lighten($primary, 20%);
		}
	}

	// btn-secondary
	&.btn-secondary {
		background-color: $red;
		border: 1px solid $red;
		color: $white;

		&:hover {
			background-color: darken($red, 25%);
			border: 1px solid darken($red, 25%);
			color: $white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		color: $red;
		border-color: $red;
		&:hover {
			background-color: lighten($red, 25%);
			color: $white;
			border-color: $white;
		}
	}

	&.btn-link {
		box-shadow: none;
		font-weight: 400;
		background-color: transparent !important;
		border: none !important;
		text-decoration: none !important;

		&:focus {
			box-shadow: none !important;
		}

		&.btn-link-primary {
			color: $primary;

			&:hover {
				color: $secondary;
			}
		}

		&.btn-link-secondary {
			color: $secondary;

			&:hover {
				color: $primary;
			}
		}

		&.btn-link-white {
			color: $white;

			&:hover {
				color: $secondary;
			}
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	font-size: 0;

	&::before {
		content: "\f137";
		font-weight: 300;
		font-family: $font-awesome;
		font-size: 1.25rem;
	}

	&:hover {
		text-decoration: none
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
