
&.assortiment-detail {
	.lead-section {
		margin-top: 0;
		margin-bottom: 1rem;
		padding-top: 1.5rem;
		padding-bottom: 1rem;
		background: $white;

		&.sticky {

            position: fixed;
            top: 44px;
            left: 0;
            right: 0;
            z-index: 999;
			box-shadow: $shadow;

			@include media-breakpoint-down(md) {
				top: 32px;
			}

			.container-holder {
				align-items: center;
				margin-bottom: 0;
			}

			.price-description,
			.usp-list {
				display: none;
			}

			.assortiment-title,
			.column {
				margin-bottom: 0;
			}

			.column.two {
				@include media-breakpoint-down(sm) {
					margin-top: 1rem;
				}
			}

			.buttons-wrapper {
				margin-bottom: 1rem;
			}
        }

		.container-one-column {
			.container-holder {
				max-width: 100%;
				margin: 0 auto;
				text-align: left;
			}
		}

		.container-two-columns {
			.container-holder {
				> .column.one {
					padding-right: 15px !important;
				}
				> .column.two {
					padding-left: 15px !important;
				}
			}
		}

		.buttons-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			column-gap: 10px;
			margin-bottom: auto;

			@include media-breakpoint-down(sm) {
				column-gap: 5px;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 1rem;
			}
		}

		.btn-back-wrapper {
			.btn {
				padding: 10px 1rem;
				font-size: 14px;
			}
			i {
				margin-right: 5px;
				font-size: 1rem;
				vertical-align: middle;
			}
		}

		// assortiment
		// .back-to-overview-link {
		// 	position: absolute;
		// 	left: -1.25rem;
		// 	top: 26px;

		// 	@include media-breakpoint-down(sm) {
		// 		left: 10px;
		// 	}

		// 	.btn-back {
		// 		margin: 0 !important;
		// 		padding: 0 !important;
		// 		display: flex;
		// 		justify-content: center;
		// 		width: 30px;
		// 		height: 30px;
		// 	}
		// }

		.assortiment-subtitle {
			font-size: 1rem;
			color: $black;
			margin: 0;
		}
		.assortiment-title {
			color: $black;
			margin-bottom: 0.5rem;
			text-indent: -2px;
			
			@include media-breakpoint-up(sm) {
				font-size: $h2-font-size;
			}
		}

		.usp-list {
			list-style: none;
			display: flex;
			padding: 0;
			margin: 0 0 10px 0;

			@include media-breakpoint-down(sm) {
				flex-wrap: wrap;
				margin: 0;
			}

			&-item {
				display: flex;
				align-items: center;
				margin-right: 1.5rem;
				@include media-breakpoint-down(sm) {
					margin-bottom: 10px;
				}
				.icon {
					position: static !important;
					width: 1.5rem;
					height: 1.5rem;
					line-height: 1.5rem;
					background-color: $box-bg;
					border-radius: 50%;
					font-size: 12px;
					text-align: center;
					color: $primary;
					margin-right: 10px;
					i {
						line-height: normal;
						&::before {
							margin-left: 0;
							margin-right: 0;
							width: 100%;
						}
					}
				}

				.title {
					font-size: 14px;
				}
			}
		}

		.price-description {
			font-weight: bold;
			font-size: 1rem;
		}

		.contact-information {
			// reageer button in header
			a {
				@extend .btn;
				background-color: $red;
				border: 1px solid $red;
				color: $white;

				@include media-breakpoint-down(sm) {
					padding-top: 10px !important;
					padding-bottom: 10px !important;
				}

				&:hover {
					background-color: darken($red, 25%);
					border: 1px solid darken($red, 25%);
					color: $white;
				}
			}
		}

		.share-information {
			position: relative;
		}

		.share-information,
		.download-information {
			> a {
				@extend .btn;
				font-weight: 400 !important;
				box-shadow: none !important;
				padding: 1rem !important;
				color: $primary;
				display: flex;
				white-space: nowrap;


				@include media-breakpoint-down(sm) {
					padding: 10px !important;
				}

				&:hover {
					background-color: lighten($primary, 20%);
					border-color: lighten($primary, 20%);
					color: $white;
				}

				i {
					margin-left: 10px;
				}
			}
		}
	}

	.collapsable-content {
		border-bottom: 1px solid $secondary;
		margin-bottom: 3rem;
		padding-bottom: 2rem;

		.collapse-inner {
			min-height: 250px;
			max-height: 250px;
			overflow: hidden;
			transition: max-height 0.3s ease;
			margin-bottom: 1rem;
		}

		a.toggle-collapse {
			margin-bottom: 1rem;
		}

		&.active {
			.collapse-inner {
				max-height: 100%;
				margin-bottom: 0;
			}
		}
	}

    .content-section {
		padding: 3rem 0;

		.container-two-columns {
			.container-holder {
				> .column {
					@include media-breakpoint-up(md) {
						--column-width: 260px;
					}

					@include media-breakpoint-up(lg) {
						--column-width: 350px;
					}

					&.one {
						@include media-breakpoint-up(md) {
							flex: 0 0 calc(100% - var(--column-width));
							max-width: calc(100% - var(--column-width));
							padding-right: 30px !important;
						}

						h3 {
							margin-bottom: 1rem;
						}
					}
					&.two {
						@include media-breakpoint-up(md) {
							flex: 0 0 var(--column-width);
							max-width: var(--column-width);
							padding-left: 3px !important;
							padding-right: 3px !important;
						}
					}
				}
			}
		}
	}

	&:has(.not-available) {

		.form-wrapper,
		.reageer-section {
			display: none;
		}
	}

	.reageer-section {
		margin: -3rem auto 0;
		padding: 0;

		@include media-breakpoint-down(sm) {
			margin-top: 0;
		}

		* {
			margin-bottom: 0 !important;
			text-align: center;
		}
	}

	.contact-section {
		.container-two-columns {
			.container-holder {
				margin-bottom: 0;
			}
		}

		.sale-form {
			a {
				text-decoration: underline;
			}
		}
	}
}
