.fancybox-container {
    .fancybox-bg {
        opacity: 1;
        background: #FFF;
    }

    &.fancybox-show-thumbs {
        .fancybox-inner {
            right: 0;
        }
    }

    .fancybox-thumbs {
        background: #FFF;
        left: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;
        width: 100%;
        padding: 3rem calc((100% - 540px) / 2);

        @include media-breakpoint-up(md) {
            padding: 3rem calc((100% - 720px) / 2);
        }

        @include media-breakpoint-up(lg) {
            padding: 3rem calc((100% - 960px) / 2);
        }

        @include media-breakpoint-up(xl) {
            padding: 3rem calc((100% - 1140px) / 2);
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            a {
                @include make-col-ready();
                @include make_col(6);
                height: 203px;
                margin: 0;
                border: 8px solid #FFF;
                background-size: contain;

                @include media-breakpoint-up(sm) {
                    @include make_col(4);
                    height: 237px;
                }

                @include media-breakpoint-up(md) {
                    height: 260px;
                }
            }
        }
    }
}