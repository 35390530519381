.page-loader {
    position: fixed;
    inset: 0;
    background-color: $secondary;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    transition: height .5s ease;

    .logo {
        // animation: name duration timing-function delay iteration-count direction fill-mode;
        animation: fade-in 2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -6rem;
    }

    .page-block.wysiwyg {
        h1 {
			color: $white;
			text-shadow: 0 0 10px rgba($black, 0.8);
		}
        
        .btn {
			width: 100%;
			max-width: 180px;
			font-size: 14px;
			margin-top: 1rem;
            padding-left: 0;
			padding-right: 0;

            @include media-breakpoint-down(sm) {
				max-width: calc((100vw /2) - 26px);
			}

			&:last-child {
				margin-left: 1rem;
			}
		}
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $grey-light;
        height: 0;
        transition: height .3s cubic-bezier(.17, .67, .8, .5);
    }

    &.active {
        &::after {
            height: 100vh;
        }
    }

    &.hide {
        height: 0;

        .page-block.wysiwyg,
        .logo {
            display: none;
        }
    }
}