.collapse-toggle-item {
    background: $box-bg;
    border-radius: 4px;
    margin-bottom: .5rem;
    padding: 1rem 1.5rem;

    &-link {
        color: $primary;
        cursor: pointer;
        &-title {
            font-weight: bold;
            margin-left: 1rem;
        }
        &-icon {
            font-size: 18px;
        }
    }

    &-content {
        margin-top: 1rem;
        display: none;
    }
}