&.assortiment-detail {
    .content-section {
        .content-holder {
            background: $box-bg;
            padding: 1.25rem;
            border-radius: 1rem;

            h3 {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0.875rem;
            }

            .address-info {
                font-weight: bold;
                color: $headings-color;
                margin-bottom: 1.25rem;
            }

            .characteristic-list {
                list-style: none;
                padding: 0;
                margin-bottom: 1.75rem;

                &-item {
                    &:first-child {
                        &-title,
                        &-value {
                            border-top: none;
                        }
                    }
                    &-title {
                        padding: 5px 0;
                        border-color: $white;
                        span {
                            font-weight: 300;
                        }
                    }
                    &-value {
                        padding: 5px 0;
                        border-color: $white;
                        span {
                            font-weight: 400;
                        }

                        &.energy_label {
                            span {
                                &[class*="A"] {
                                    --label-color: #04A551;
                                }
                                &.B {
                                    --label-color: #52B848;
                                }
                                &.C {
                                    --label-color: #BDD630;
                                }
                                &.D {
                                    --label-color: #FEF102;
                                }
                                &.E {
                                    --label-color: #FDB813;
                                }
                                &.F {
                                    --label-color: #F46F20;
                                }
                                &.G {
                                    --label-color: #ED1B24;
                                }

                                display: block;
                                background-color: var(--label-color);
                                color: $white;
                                width: 80px;
                                position: relative;
                                text-align: right;
                                padding: 0 5px;
                                font-size: 20px;
                                font-weight: bold;
                                line-height: normal;
                                &::after {
                                    --rectangle-width: 12px;
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: calc((var(--rectangle-width) * -1) + 1px);
                                    display: block;

                                    border-top: var(--rectangle-width) solid transparent;
                                    border-left: calc(var(--rectangle-width) * 1) solid;
                                    border-bottom: var(--rectangle-width) solid transparent;

                                    border-left-color: var(--label-color);
                                }
                            }
                        }
                    }
                }
            }

            .characteristic-extra-list {
                list-style: none;
                padding: 0;
                margin-bottom: 1.75rem;

                &-item {
                    display: flex;
                    &-icon {
                        flex: 0 0 1rem;
                        max-width: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .fa-check {
                            color: $primary;
                        }
                    }

                    &-title {
                        margin-left: 10px;
                    }

                    &:has(.fa-times) {
                        color: $secondary;
                    }
                }
            }
        }
    }
}