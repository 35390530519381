.owl-carousel {
	&.owl-loaded {
		&::before {
			display: none;
		}
	}
}

.horizontal-carousel-section {
	position: relative;

	.container.container-one-column {
		position: absolute;
		inset: 0;
		z-index: 2;
		pointer-events: none;

		.container-holder {
			height: 100%;
			.gallery-label {
				position: absolute;
				background: $primary;
				color: $white;
				border-radius: 5px;
				font-size: 1rem;
				line-height: normal;
				font-weight: bold;
				font-family: $font-family-secondary;
				padding: 10px 12px;
				left: 15px;
				top: -20px;
			}

			.gallery-caption {
				position: absolute;
				font-size: 14px;
				font-weight: bold;
				background: $white;
				border: 1px solid $primary;
				border-radius: 3px;
				color: $primary;
				padding: 10px 18px;
				right: 1rem;
				bottom: 1rem;
				line-height: normal;
				pointer-events: all;

				i {
					margin-left: 10px;
					line-height: normal;
				}

				&::before {
					display: none;
				}
			}
		}
	}

	.container-carousel {
		overflow: hidden;
		.column {
			.carousel-holder {
				overflow: hidden;

				.horizontal-carousel {
					.swiper-slide {

						@include media-breakpoint-down(sm) {
							height: 275px !important;
                            width: auto !important;

							img {
								height: 275px !important;
								width: auto !important;
							}
						}

						img {
							border-radius: 5px;

						}

						&.youtube-item {
							.youtube-item-icon {
								display: flex;
								justify-content: center;
								align-items: center;
								position: absolute;
								top: 0; left: 0;
								width: 100%; height: 100%;
								font-size: 80px;
								color: white;

								i {
									background-color: $primary;
									border-radius: 50%;
									width: 72px;
									height: 72px;
									text-align: center;
									display: flex;
									align-items: center;
									justify-content: center;
								}

								@include media-breakpoint-down(sm) {
									font-size: 40px;

									i {
										width: 36px;
										height: 36px;
									}
								}
							}

							img {
								height: 100%;
							}
						}
					}

					.swiper-lazy-preloader {
						animation: spin 1s linear infinite;
					}

					.swiper-button {
						background-color: $white;
						border: 1px solid $primary;
						border-radius: 4px;
						color: $primary;
						text-shadow: none;
						width: 50px;
						height: 50px;
						font-size: 18px;

						&::after {
							font-family: "Font Awesome 5 Pro";
							font-size: 18px;
						}

						&-prev {
							left: 25px;

							&::after {
								content: "\f053";
							}
						}

						&-next {
							right: 25px;

							&::after {
								content: "\f054";
							}
						}

						&-disabled {
							pointer-events: initial;
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}
}
