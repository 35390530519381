@keyframes scale-up {
    0% {
        // transform: scale(1);
        background-size: auto 100%;
    }

    100% {
        // transform: scale(1.2);
        background-size: auto 125%;
    }
}

@keyframes scale-down {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(.8);
    }

}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes paper-plane-fly {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
