.breadcrumbs {
    background: $section-bg;
    padding: 10px 0;

    .column.default {
        flex-flow: row nowrap;
    }

    .breadcrumb-title {
        font-size: 14px;
        border-right: 1px solid $secondary;
        padding-right: 1.5rem;
        margin-right: 1.5rem;
    }

    .breadcrumb-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-content: center;
        font-size: 14px;

        @include media-breakpoint-down(sm) {
            overflow-x: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .breadcrumb-list-item {
            white-space: nowrap;
            &:not(:last-child) {
                padding-right: .75rem;
                &::after {
                    content: '\f054';
                    font-family: $font-awesome;
                    font-weight: 300;
                    vertical-align: middle;
                    margin-left: .75rem;
                }
            }
        }
    }
}