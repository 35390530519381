// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 400px;
	max-height: 1080px;

	@include media-breakpoint-up(lg) {
		height: 100vh;
	}
}

.eyecatcher.large {
	position: relative;
	.logo {
		display: none;
		position: absolute;
		left: 50%;
		top: 50%;
		@include prefix(transform, translate(-50%));
		z-index: 1;
		margin-top: -12.5rem;
		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	.owl-description {
		h1 {
			color: $white;
			text-shadow: 0 0 10px rgba($black, 0.8);
		}

		.btn {
			width: 100%;
			max-width: 180px;
			font-size: 14px;
			margin-top: 2rem;
			padding-left: 0;
			padding-right: 0;

			@include media-breakpoint-down(sm) {
				max-width: calc((100vw /2) - 26px);
			}

			&:last-child {
				margin-left: 1rem;
			}
		}
	}


	// animation
	.owl-carousel {
		.owl-stage {
			.owl-item {
				&.active {
					&.animate {
						.item {
							animation: scale-up 5s ease-out forwards;
						}
						
						// .owl-caption-holder {
						// 	animation: scale-down 5s ease forwards;
						// }
					}
				}
			}
		}
	}
}