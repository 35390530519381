// mixins

// adds vendor prefixes
@mixin prefix($property, $value) {
    $prefixes: webkit, moz, ms, o;
    @each $prefix in $prefixes {
        -#{$prefix}-#{$property}: $value;
    }
    #{$property}: $value;
}

// icon size
@mixin icon-size($size) {
    display: inline-block;
    font-size: $size; 
	width: $size;
	height: $size;
	line-height: $size;
}