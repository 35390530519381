// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 3rem 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-3rem + 30px);
}

// lead-section
// =========================================================================
&.home {
	.lead-section {
		.container-one-column {
			.container-holder {
				max-width: 830px;
				margin: 0 auto;
				text-align: center;
			}
		}
	}
}
.lead-section {
	padding: 3rem 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	padding: 5rem 0;
}

// services-section
// =========================================================================
.services-section {
	padding: 3rem 0 5rem;

	.wysiwyg {
		max-width: 800px;
		margin: auto;
	}
}

// image-bg-section
// =========================================================================
.image-bg-section {
	position: relative;
	overflow: hidden;
	padding: 200px 0;

	.column {
		z-index: 1;
	}

	&.sm-padding {
		padding: 100px 0;

		.resource-image {
			&::before {
				content: "";
				inset: 0;
				position: absolute;
				background-color: $primary;
				opacity: 0.67;
				display: block;
				z-index: 1;
			}

			img {
				@include media-breakpoint-down(md) {
					@include prefix(transform, translateX(-45%));
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		padding: 100px 0;
	}
	.resource-image {
		position: absolute;
		inset: 0;

		img {
			width: 100%;
			height: 100%;
			@include media-breakpoint-down(md) {
				max-width: none;
				width: auto;
			}
		}
	}

	.h1 {
		text-shadow: 0 0 10px rgba($black, 0.8);
	}

	.btn {
		width: 250px;
	}
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3rem 0;
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3rem 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	@include media-breakpoint-down(sm) {
		margin-top: 2.5rem;
	}
}

// booking-section
// =========================================================================
.booking-section {
	padding: 3rem 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3rem 0;

	.container-two-columns {
		margin-bottom: 1.5rem;
		.container-holder {
			background-color: $primary;
			border-radius: 1rem;
			margin-left: 0;
			margin-right: 0;
			padding: 2rem 3rem 0;
			@include media-breakpoint-up(lg) {
				padding: 2rem 6rem 0;
			}

			> .column {
				padding-left: 15px !important;
				padding-right: 15px !important;

				--column-width: 380px;

				&.one {
					@include media-breakpoint-up(lg) {
						flex: 0 0 var(--column-width);
						max-width: var(--column-width);
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						flex: 0 0 calc(100% - var(--column-width));
						max-width: calc(100% - var(--column-width));
					}
				}
			}

			h2 {
				color: $white;
				margin-top: 1rem;
			}
		}
	}
}
