// header
.header {
	z-index: 998;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s;

	.container,
	.container-fluid {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		@include media-breakpoint-up(lg) {
			padding-left: 4rem;
			padding-right: 4rem;
		}
		.container-holder {
			>.column {
				align-items: center;
				@include media-breakpoint-up(lg) {
					justify-content: space-between;
				}

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}
	
	&.sticky {
		background: $white;
		box-shadow: $shadow;
		position: fixed;

		.header-top {
			background: $primary;
			.page-block {
				&.link {
					background: $section-bg;
					.link {
						color: $link-color;
					}
				}
			}
		}

		.menu-toggle {
			.navbar-toggler {
				background: $white;
				&:hover {
					background-color: $box-bg;
				}
			}
		}

		.logo {
			display: block;
		}
	
		.menu {
			&#extra_menu {
				@include media-breakpoint-up(lg) {
					display: flex !important;
				}
			}
		}
	}

	.header-top {
		@include prefix(transition, .5s);
		.page-block {
			&.link {
				margin-left: auto;
				padding: 4px 1.5rem;
				@include prefix(transition, .5s);
				@include media-breakpoint-up(lg) {
					padding: 10px 1.5rem;
				}
				.link {
					padding: 0;
					font-size: 14px;
					color: $box-bg;
					background: unset;

					i {
						font-size: 1rem;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}

			&.site-switcher {
				margin-left: 20px;
				.navbar-nav {
					align-items: center;
					.nav-item {
						&.active {
							display: none;
						}

						.nav-link {
							border: none;
							box-shadow: none;
							color: $box-bg;
							font-size: 14px;

							&:hover {
								text-decoration: underline;
							}
						}
					}
					&::before {
						content: '\f0ac';
						font-family: $font-awesome;
						font-weight: 300;
						margin-right: 5px;
						color: $box-bg;
						font-size: 1rem;
						@include prefix(transition, .5s);
					}
				}

			}
		}
	}

	.header-main {

		overflow-y: auto;
		max-height: calc(100vh - 32px);

		.page-block {
			&.link {
				display: none;
				&:last-child {
					margin-left: 30px;
					.link {
						margin-left: 0;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin: 28px 0;
		.navbar-toggler {
			background: $box-bg;
			color: $body-color;
			display: flex !important;
			align-items: center;
			padding: 10px 1rem;
			@include prefix(transition, .5s);

			&:hover {
				background: darken($box-bg, 15%);
			}
			
			@include media-breakpoint-up(lg) {
				padding: 1rem 1.5rem;
			}

			&[aria-expanded=false] {
				.navbar-toggler-icon {
					.fa-times {
						display: none;
					}
				}
			}

			&[aria-expanded=true] {
				color: $secondary;
				.navbar-toggler-icon {
					.fa-bars {
						display: none;
					}
				}
			}

			.navbar-toggler-icon {
				font-size: 1rem;
				vertical-align: middle;
				@include media-breakpoint-up(lg) {
					margin-right: 10px;
				}
			}

			.navbar-toggler-label {
				font-size: 14px;
				display: none;
				@include media-breakpoint-up(lg) {
					display: inline;
				}
			}
		}
	}

	// logo
	.logo {
		// margin: 1rem 0;
		display: none;
		margin: auto;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		// order: 1;
		// max-width: 100%;
		&#extra_menu {
			flex-grow: 0;
			flex-basis: auto;
			display: none !important;

			.navbar-nav {
				flex-direction: row;

				.nav-item {
					.nav-link {
						padding-right: 0.5rem;
						padding-left: 0.5rem;
						
						&:hover {
							font-weight: 600;
						}
					}
				}

			}
		}

		&#default_menu {
			&.collapse:not(.show) {
				display: none !important;
			}

			&.navbar-collapse {
				display: block !important;
			}

			.navbar-nav {
				@include media-breakpoint-up(md) {
					margin-top: 3rem;
					flex-direction: row;
				}
				width: 100%;
				font-size: 14px;
				flex-wrap: wrap;
				justify-content: space-around;
				> .nav-item {
					min-width: 120px;
					> .nav-link {
						color: $primary;
						&[href="javascript:void(0);"] {
							cursor: default;
						}
						&::after {
							display: none;
						}

						.menu-icon {
							margin-right: 10px;
							color: $secondary;
						}

					}

					&.last {
						> .nav-link {
							width: max-content;
							margin: auto;
							border-radius: 4px;
							position: relative;
							padding-left: 31px;

							.menu-icon {
								position: absolute;
								width: 40px;
								height: 16px;
								text-align: left;
								left: 8px;
								transform-origin: right;
							}

							&:hover {
								font-weight: 600;
								.menu-icon {
									animation: paper-plane-fly 3s;
								}
							}
						}
					}

					.dropdown-menu {
						all: unset !important;
						position: static;
						display: block;

						&.show {
							&::before {
								display: none;
							}
						}

						.nav-item {
							> .nav-link {
								color: $black;
								font-weight: bold;
								overflow: hidden;
								
								.menu-title {
									position: relative;
									&::before {
										content: '\f178';
										font-family: $font-awesome;
										position: absolute;
										left: -22px;
									}
								}

							}

							&.active,
							&:hover {
								> .nav-link {
									padding-left: 30px;
								}
							}
						}
					}

					&.last {
						flex: 0 0 100%;
						max-width: 100%;
						text-align: center;
						padding-top: 3rem;
						padding-bottom: 30px;
					}
				}
			}
		}
	}
}
