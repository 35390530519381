.card {
    background-color: $box-bg;
    border-color: $box-bg;
    @include prefix(transition, .2s);
    border-radius: 0 0 1rem 1rem;

    .card-image {
        overflow: visible;
        // property label
        .card-image-caption {
            left: 20px;
            right: auto;
            bottom: -.5rem;
            background: unset;

            .card-image-label {
                background: $primary;
                color: $white;
                border-radius: 5px;
                font-size: 1rem;
                line-height: normal;
                font-weight: bold;
                font-family: $font-family-secondary;
                padding: 10px 12px;
            }
        }
        picture {
            overflow: hidden;
            display: block;
        }
    }

    .card-body {
        padding: 2.25rem 1.875rem;
        .card-caption {
            .card-title {
                font-size: 18px;
                font-weight: bold;
                color: $primary;
                font-family: $font-family-primary;
                margin-bottom: .5rem;
            }

            .card-subtitle {
                font-size: 1rem;
            }

            .list-icons {
                list-style: none;
                .list-item {
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    .icon {
                        position: static !important;
                        width: 1.5rem;
                        height: 1.5rem;
                        line-height: 1.5rem;
                        background-color: $white;
                        border-radius: 50%;
                        font-size: 12px;
                        text-align: center;
                        color: $primary;
                        margin-right: 10px;
                        i {
                            line-height: normal;
                        }
                    }
    
                    .label {
                        font-size: 14px;
                    }
                }
            }

            p {
                margin: 0;
                line-height: 20px;
                max-width: 270px;
            }
        }

        .card-buttons {
            display: none;
        }
    }

    // hover on card
    &:hover {
        border-color: $box-bg;
        background-color: $white;

        .card-body {
            .card-caption {
                .list-icons {
                    .list-item {
                        .icon {
                            background-color: $box-bg;
                        }
                    }
                }
            }
        }
    }

    // card with icon
    &.icon {
        border-radius: 1rem;
        padding: 2.5rem;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-down(md) {
            padding: 1rem;
        }

        @include media-breakpoint-down(sm) {
            padding: 1.5rem;
        }

        .card-body {
            text-align: left;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 2.25rem;
            @include media-breakpoint-down(md) {
                padding-left: 1rem;
                padding-right: 0;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 1.5rem;
            }
        }
    }

    // properties card
    &.card-assortiment {
        
        .card-body {
            padding-top: 10px;
            padding-right: 1rem;

            .card-caption {
                display: flex;
                flex-flow: column wrap;

                .card-prices {
                    order: 0;
                    margin: 0;
                    text-align: right;
                    font-size: 14px;

                    .card-price {
                        font-weight: 400;
                    }
                }

                .card-subtitle {
                    order: 1;
                    margin-top: 10px;
                    margin-bottom: 4px;
                    font-family: $font-family-primary;
                }

                .card-title-link {
                    order: 2;
                }

                .card-description {
                    order: 3;
                }
            }
        }
    }
}