//body
&:not(.home) {
	padding-top: 150px;

	@include media-breakpoint-down(md) {
		padding-top: 126px;
	}
}

&.mce-content-body {
	padding-top: 0;
	padding: 1rem;
}

// text selection
::-moz-selection { /* Code for Firefox */
	color: $white;
	background: $primary;
}

::selection {
	color: $white;
	background: $primary;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 1rem;

	@include media-breakpoint-up(md) {
		padding: 2rem;
	}

	@include media-breakpoint-up(xl) {
		padding: 3rem;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
// .default-card {}

// backgrounds
.bg-dark {
	background-color: $grey !important;
}

.bg-light {
	background-color: $grey-light !important;
}

// hide cookie btn
#w3-cookie-consent-widget {
	display: none;
}
