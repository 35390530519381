.collection.grid {
    &:has(.card.icon) {
        margin-top: 2.5rem;
        .grid-items {
            .item {
                flex: 0 0 100%;
                max-width: 100%;
                @include media-breakpoint-up(sm) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                margin-bottom: 1.25rem;
            }
        }
    }
}

// Footer employees collection
.employees-collection.collection.grid {
    .grid-items {
        margin: 0;
        .item {
            padding-left: 30px;
            padding-right: 30px;
            flex: 0 0 180px;
            max-width: 180px;
            margin-bottom: 1.25rem;

            .card {
                background-color: unset;
                border: none;

                .card-image {
                    margin-bottom: 20px;
                    &:hover {
                        .card-img {
                            transform: scale(1);
                        }    
                    }
                    .card-img {
                        border-radius: 50%;
                        aspect-ratio: 1;
                        width: 100%;
                    }
                }

                .card-body {
                    padding: 0;
                    z-index: 1; 
                    .card-caption {
                        position: relative;
                        .card-title {
                            font-size: 14px;
                            color: $text-dark;
                        }
    
                        .card-subtitle {
                            font-size: 12px;
                            color: $text-dark;
                        }
                        .list-icons {
                            padding: 0;
                            margin: 0;
                            display: flex;
                            justify-content: center;
                            position: absolute;
                            left: 50%;
                            @include prefix(transform, translateX(-50%));
                            @include prefix(transition, .3s);
                            top: -10px;
                            opacity: 0;

                            .list-item {
                                @include prefix(transition, .3s);
                                &:not(:last-child) {
                                    margin-right: 10px;
                                }
                                &:hover {
                                    @include prefix(transform, translateY(-5px));
                                }
                                .icon {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .card-body {
                        .card-caption {
                            .list-icons {
                                top: -2rem;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}