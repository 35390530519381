// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f058';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

// platforms-list
.platforms-list {
	padding: 0;
	margin: 0;
	list-style: none;
	position: absolute;
	top: -1rem;
	left: 50%;
	z-index: -1;
	opacity: 0;
	@include prefix(transform, translateX(-50%));
	@include prefix(transition, all .5s ease-in-out);

	&-item {
		margin-bottom: 5px;

		&-link {
			display: block;
			text-decoration: none;
	
			i {
				width: 2rem;
				height: 2rem;
				line-height: 2rem;
				border-radius: 50%;
				text-align: center;
				background-color: $box-bg;
				color: $primary;

				&:hover {
					color: $box-bg;
					background-color: $primary;
				}
			}
		}
	}

	&.open {
		z-index: 999;
		top: 65px;
		opacity: 1;
	}
}